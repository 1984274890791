//
// Main init file of global bootstrap and theme functions, mixins, variables and config
//

// Custom functions & mixins
@import "./core/base/functions";
@import "./core/base/mixins";
@import "./core/components/mixins";
@import "./core/vendors/plugins/mixins";

// Custom variables
@import "components/variables.custom";
@import "./core/components/variables";

// Bootstrap initializaton
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// 3rd-Party plugins variables
@import "./core/vendors/plugins/variables";

@import "@toast-ui/calendar/dist/toastui-calendar.min.css";
@import "tui-date-picker/dist/tui-date-picker.css";
@import "tui-time-picker/dist/tui-time-picker.css";
@import "react-datepicker/dist/react-datepicker.css";

// Custom layout variables
@import "layout/variables";
